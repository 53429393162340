import { Card, Typography, Result, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./dashboard.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Home from "../home/Home";
import { fetchTeacherExams } from "../../redux/slices/Questions";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/Title/PageTitle";

const TeacherDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const exams = useSelector((state) => state.exam.exam_s);
  const status = useSelector((state) => state.exam.status);
  let rendCourse;

  useEffect(() => {
    const loadCourses = async () => {
      await dispatch(fetchTeacherExams());
    };

    loadCourses();
  }, [dispatch]);
  console.log(exams);
  if (status === "loading" || status === "idle") {
    return (
      <div style={{ display: "flex", justifyContent: "center", padding: 50 }}>
        <Spin size="large" />
      </div>
    );
  }
  let renderExams;
  if (localStorage.getItem("role") === "4" && exams) {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in "YYYY-MM-DD" format
    console.log(currentDate);

    const filteredExams = exams.filter(
      (exam) => exam?.start_date.split(" ")[0] === currentDate
    );

    if (filteredExams.length > 0) {
      // Display at most the first two exams
      const examsToDisplay = filteredExams.slice(0, 2);

      renderExams = examsToDisplay.map((exam) => (
        <div className="cards-students" key={exam?.id}>
          <Card
            className="test-card"
            onClick={() => {
              navigate(`/students/${exam?.id}`);
            }}
          >
            <Typography className="course-names">{exam?.title}</Typography>
            <Typography className="course-names">
              {exam?.description}
            </Typography>
            <Typography className="number-students">
              <span className="mark">{exam?.total_marks}</span> العلامة الكاملة
            </Typography>
            <Typography className="date"> {exam?.start_date}</Typography>
          </Card>
        </div>
      ));
    } else if (status === "loading" || status === "idle") {
      return (
        <div style={{ display: "flex", justifyContent: "center", padding: 50 }}>
          <Spin size="large" />
        </div>
      );
    }
  } else {
    renderExams = <Result icon={<LoadingOutlined />} title="Loading..." />;
  }

  // Use `renderExams` in your component
  // For example, return it inside a div or another container element

  return (
    <Home>
      <div>
        <div className="d-title">
          <PageTitle props={{ color: "#2f3ced" }}>اهلا بعودتك!</PageTitle>

          <div
            style={{
              display: "flex",
              padding: "20px 0px 10px 10px ",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Typography className="test-title"> آخر الإختبارات</Typography>
            <div className="courses-info">
              {/* <Card
                className="single-course"
                onClick={() => {
                  navigate("/Tests");
                }}
              >
                <Typography className="course-name">
                  سكيتش من الصفر الى الاحتراف
                </Typography>

                <Typography className="number-students">
                  <span style={{ color: "#F2C94C" }}>70</span>/100 طالب متقدم
                </Typography>
                <Typography className="date"> 12/5/2023</Typography>
              </Card>
              <Card
                className="single-course"
                onClick={() => {
                  navigate("/Tests");
                }}
              >
                <Typography className="course-name">
                  سكيتش من الصفر الى الاحتراف
                </Typography>

                <Typography className="number-students">
                  <span style={{ color: "#F2C94C" }}>70</span>/100 طالب متقدم
                </Typography>
                <Typography className="date"> 12/5/2023</Typography>
              </Card> */}
              {renderExams}
            </div>
          </div>
        </div>
        {/* <div className="leftDashboard">
          <div
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              width: "50%",
              height: "17%",
              marginRight: 20,

              paddingBottom: 10,
            }}
          >
            <Typography className="left-title">البثوث المقبلة</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Card
              style={{
                marginRight: 20,
                border: "none",
                borderRadius: 16,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
                padding: 15,
                width: "80%",
                height: "30%",
              }}
            >
              <Card.Grid
                hoverable={false}
                style={{
                  padding: 0,
                  display: "flex",
                  // justifyContent: "center",
                  boxShadow: "none",
                  width: "20%",
                  // height: "100%",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFA2C0",
                    width: "90%",
                    height: "100%",
                    borderRadius: 16,
                    position: "relative",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <Button
                    icon={<img src="ci.svg" />}
                    style={{
                      backgroundColor: "#F7F7F7",
                      position: "absolute",
                      bottom: 10,
                      borderRadius: 18,
                    }}
                  />
                </div>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "60%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // height: "10%",
                  padding: 0,
                }}
              >
                <Typography className="live-title">
                  إبدأ ببناء أول تطبيق
                </Typography>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontSize: 12,
                    color: "#808191",
                    padding: 0,
                  }}
                  onClick={() => {
                    navigate("/live");
                  }}
                >
                  بث الآن
                </Button>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  boxShadow: "none",
                  marginTop: 5,
                }}
              >
                <LeftOutlined />
              </Card.Grid>
            </Card>{" "}
            <Card
              style={{
                marginRight: 20,
                border: "none",
                borderRadius: 16,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
                padding: 15,
                width: "80%",
                height: "30%",
              }}
            >
              <Card.Grid
                hoverable={false}
                style={{
                  padding: 0,
                  display: "flex",
                  // justifyContent: "center",
                  boxShadow: "none",
                  width: "20%",
                  // height: "100%",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFCE73",
                    width: "90%",
                    height: "100%",
                    borderRadius: 16,
                    position: "relative",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <Button
                    icon={<img src="skech.svg" />}
                    style={{
                      backgroundColor: "#F7F7F7",
                      position: "absolute",
                      bottom: 10,
                      borderRadius: 18,
                    }}
                  />
                </div>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "60%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // height: "10%",
                  padding: 0,
                }}
              >
                <Typography className="live-title">
                  إبدأ ببناء أول تطبيق
                </Typography>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontSize: 12,
                    color: "#808191",
                    padding: 0,
                  }}
                  onClick={() => {
                    navigate("/live");
                  }}
                >
                  بث الآن
                </Button>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  boxShadow: "none",
                  marginTop: 5,
                }}
              >
                <LeftOutlined />
              </Card.Grid>
            </Card>{" "}
            <Card
              style={{
                marginRight: 20,
                border: "none",
                borderRadius: 16,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
                padding: 15,
                width: "80%",
                height: "30%",
              }}
            >
              <Card.Grid
                hoverable={false}
                style={{
                  padding: 0,
                  display: "flex",
                  // justifyContent: "center",
                  boxShadow: "none",
                  width: "20%",
                  // height: "100%",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#A0D7E7",
                    width: "90%",
                    height: "100%",
                    borderRadius: 16,
                    position: "relative",
                    justifyContent: "center",
                    // padding: 2,
                    // marginBottom: 2,
                  }}
                >
                  <Button
                    icon={
                      <img
                        src="principle.svg"
                        style={{
                          position: "absolute",
                          bottom: -2,
                          borderRadius: 18,
                          left: -1,
                        }}
                      />
                    }
                    style={{
                      backgroundColor: "#F7F7F7",
                      position: "absolute",
                      bottom: 12,
                      borderRadius: 18,
                    }}
                  />
                </div>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "60%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // height: "10%",
                  padding: 0,
                }}
              >
                <Typography className="live-title">
                  إبدأ ببناء أول تطبيق
                </Typography>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontSize: 12,
                    color: "#808191",
                    padding: 0,
                  }}
                  onClick={() => {
                    navigate("/live");
                  }}
                >
                  بث الآن
                </Button>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  boxShadow: "none",
                  marginTop: 5,
                }}
              >
                <LeftOutlined />
              </Card.Grid>
            </Card>{" "}
          </div>
          <div style={{ marginRight: 20 }}>
            <Typography className="students-number-title">
              عدد الطلاب
            </Typography>
          </div>
          <Chart />
        </div> */}
      </div>
    </Home>
  );
};

export default TeacherDashboard;
