import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Input,
  Form,
  Spin,
  Radio,
  Modal,
  message,
  Result,
  Upload,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  SmileOutlined,
  FrownOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./ExamQuestionType1.scss";
import {
  postStudentAnswers,
  selectPostStudentAnswersMessage,
} from "../../redux/slices/Questions";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../home/Home";
import { fetchStudentExams, clearError } from "../../redux/slices/Questions";
import AddExamUpload from "../addNewExam/AddExamUpload";
const { TextArea } = Input;
const navigate = useNavigate;
const ExamQuestionType1 = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exam.exam_s);
  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [examData, seExamData] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { status, error } = useSelector((state) => state.exam);
  const [uploadedFile, setUploadedFile] = useState(null);

  const { id } = useParams();
  const [questionStates, setQuestionStates] = useState([]);
  const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
  const updateQuestionState = (index, fieldName, value) => {
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = { ...newStates[index], [fieldName]: value };

      return newStates;
    });
  };
  const [loading, setLoading] = useState("تنزيل");
  useEffect(() => {
    if (localStorage.getItem("role") == "5" && exams) {
      const loadExams = async () => {
        await dispatch(fetchStudentExams(id));
        dispatch(clearError());
      };

      loadExams();
    }
  }, []);

  const handleAddQuestionClick = () => {
    const questionData = exams?.exam?.questions.map((question, index) => {
      if (question.type === "text") {
        return {
          id: question.id,
          type: question.type,
          answer: questionStates[index]?.textAnswer || "لم يتم الاجابة ",
        };
      } else if (question.type === "options") {
        return {
          id: question.id,
          type: question.type,
          options: question.options.map((option) => ({
            id: option.id,
            option: option.title,
            options_selected:
              option.id === questionStates[index]?.selectedOption,
          })),
        };
      } else if (question.type === "file") {
        return {
          id: question.id,
          type: question.type,
          file: questionStates[index]?.file || uploadedFile || null,
        };
      }
      return null;
    });

    seExamData({
      exam_id: exams?.exam?.id,
      questions: questionData.filter((q) => q !== null),
    });
    console.log("new", examData);
    setTitle(exams?.exam?.title);
  };

  const handleNextQuestion = () => {
    const currentQuestion = exams?.exam?.questions[currentQuestionIndex];

    if (
      currentQuestion.type === "text" &&
      !questionStates[currentQuestionIndex]?.textAnswer
    ) {
      message.error("يرجى الاجابة على السؤال");
      return;
    }
    if (
      currentQuestion.type === "options" &&
      !questionStates[currentQuestionIndex]?.selectedOption
    ) {
      message.error("يرجى اختيار الجواب الصحيح");
      return;
    }

    // Add a validation check for file type questions
    if (
      currentQuestion.type === "file" &&
      !questionStates[currentQuestionIndex]?.file
    ) {
      message.error("يرجى رفع الملف");
      return;
    }

    if (currentQuestionIndex < exams?.exam?.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const allQuestionsAnswered = questionStates.every(
        (state) => state?.textAnswer || state?.selectedOption
      );

      // Add a validation check for file type questions

      if (exams?.exam?.questions?.length === questionStates?.length) {
        // Display the modal form
        setIsModalVisible(true);
        handleAddQuestionClick();
      } else {
        // Handle the end of the exam without a modal form
        handleAddQuestionClick();
      }
    }
  };

  const handleModalOk = () => {
    dispatch(postStudentAnswers(examData));

    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  const handleFileUpload = (info) => {
    if (info.file && info.file.status === "done") {
      setUploadedFile(info.file.originFileObj);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file && info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  console.log(examData);
  const downloadFile = async (file) => {
    try {
      setLoading(<LoadingOutlined />);

      const response = await fetch(file, { redirect: "follow" });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      // Extract filename from the URL (assuming the filename is at the end of the URL)
      const filename = file.substring(file.lastIndexOf("/") + 1);
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading("تنزيل");

      window.open(file, "_blank");
    }
  };
  console.log(error);
  return (
    <Home>
      {contextHolder}

      {status === "loading" || status === "idle" ? (
        <div className="main-exam">
          <Spin size="large" />
        </div>
      ) : status == "succeeded" && !error ? (
        <div className="main-exam">
          <div className="live-answers">
            <div>
              <Typography className="live-title">
                {exams?.exam?.title}
              </Typography>
            </div>
            <Typography className="related-title">
              {exams?.exam?.description}
            </Typography>
            <div className="page-number-container">
              <span className="related-title">
                صفحة{" "}
                <span className="page-number">{currentQuestionIndex + 1}</span>{" "}
                من {exams?.exam?.questions.length}
              </span>
            </div>
          </div>

          <Form>
            {exams?.exam?.questions.map((question, index) => (
              <div
                key={question?.id}
                style={{
                  display: index === currentQuestionIndex ? "block" : "none",
                }}
              >
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      width: "100%",
                    }}
                  >
                    <div className="question-number">
                      <Typography className="page-number">
                        {index + 1}
                      </Typography>
                    </div>
                    <Typography className="related-title">
                      {question?.title} : {question?.description} ؟
                    </Typography>
                  </div>
                </Form.Item>
                {question.type === "text" && (
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "30%",
                        justifyContent: "space-between",
                        gap: 20,
                      }}
                    >
                      <TextArea
                        placeholder={question?.description}
                        style={{
                          height: 170,
                          resize: "none",
                          borderRadius: 16,
                        }}
                        value={questionStates[index]?.textAnswer || ""}
                        onChange={(e) =>
                          updateQuestionState(
                            index,
                            "textAnswer",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </Form.Item>
                )}
                {question.type === "options" && (
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "80%",
                        justifyContent: "flex-start ",
                      }}
                    >
                      <Radio.Group
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          color: "#11142D",
                        }}
                        value={
                          questionStates[index]?.selectedOption || undefined
                        }
                        onChange={(e) =>
                          updateQuestionState(
                            index,
                            "selectedOption",
                            e.target.value
                          )
                        }
                      >
                        {question.options.map((option) => (
                          <div
                            key={option.id}
                            style={{
                              border: "1px solid #808191",
                              borderRadius: 14,
                              width: "25%",
                            }}
                          >
                            <Radio value={option.id} style={{ padding: 10 }}>
                              {option.title}
                            </Radio>
                          </div>
                        ))}
                      </Radio.Group>
                    </div>
                  </Form.Item>
                )}
                {question.type === "file" && (
                  <Form.Item>
                    <Button
                      size="large"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 16,
                        color: "#11142d",
                      }}
                      onClick={() => {
                        downloadFile(question?.file);
                      }}
                    >
                      {loading}
                    </Button>
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          // justifyContent: "space-between",
                          gap: 20,
                        }}
                      >
                        <Typography className="related-title">
                          ملف الاجابة :
                        </Typography>
                        <Dragger
                          name="file"
                          multiple
                          //  onChange={handleFileUpload}
                          onDrop={(e) => {
                            console.log("Dropped files", e.dataTransfer.files);
                          }}
                          onChange={(info) => handleFileUpload(info)}
                          beforeUpload={(file) => {
                            if (!allowedFileTypes.includes(file.type)) {
                              message.error("JPG,PNG,JPEG,PDF  فقط!");
                              return false; // Cancel the upload
                            }
                            updateQuestionState(index, "file", file);

                            // Handle the file upload logic here
                            // handleFileUpload({ file });
                            // Continue with the default upload behavior
                            return false;
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #A3A3A5",
                            borderRadius: 18,
                            width: 400,
                            height: "100%",
                            padding: 12,
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined
                              style={{ fontSize: 20, color: "#11142D" }}
                            />
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              padding: 0,
                            }}
                          >
                            <b> اضغط للرفع</b>
                            <p className="ant-upload-text">
                              أو السحب و الافلات
                            </p>
                            <p className="ant-upload-hint">
                              PNG, JPEG or JPG (max 800*800px)
                            </p>
                          </div>
                        </Dragger>
                      </div>
                    </Form.Item>
                  </Form.Item>
                )}
              </div>
            ))}
            <div>
              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                {currentQuestionIndex > 0 && (
                  <Button
                    style={{
                      border: "1px solid #A3A3A5",
                      borderRadius: 16,
                      color: "#fff",
                    }}
                    onClick={handlePreviousQuestion}
                    size="large"
                  >
                    السؤال السابق
                  </Button>
                )}
                <Button
                  style={{
                    backgroundColor: "#11142D",
                    border: "1px solid #A3A3A5",
                    borderRadius: 16,
                    color: "#fff",
                  }}
                  onClick={handleNextQuestion}
                  size="large"
                >
                  {currentQuestionIndex < exams?.exam?.questions.length - 1
                    ? "السؤال التالي"
                    : "انهاء الاختبار"}
                </Button>
              </div>
            </div>
            <Modal
              width={"35%"}
              title={
                <Typography style={{ textAlign: "center", fontSize: 40 }}>
                  هل أنت متأكد؟
                </Typography>
              }
              visible={isModalVisible}
              onCancel={handleModalCancel}
              footer={(_, {}) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    size="large"
                    onClick={handleModalCancel}
                    style={{
                      backgroundColor: "#fff",
                      color: "#11142D",
                      border: "1px solid #11142D",
                      width: "25%",
                      borderRadius: 16,
                    }}
                  >
                    الغاء
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      handleModalOk();
                      // navigate("/dashboard");
                    }}
                    style={{
                      backgroundColor: "#11142D",
                      color: "#fff",
                      width: "25%",
                      borderRadius: 16,
                    }}
                  >
                    تأكيد
                  </Button>
                </div>
              )}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#11142D",
                  fontSize: "16px",
                }}
              >
                يرجى التأكد من جميع الاجوبة لانه لا يمكن تغييرها بعد هذه
                المرحلة!
              </p>
            </Modal>
          </Form>
        </div>
      ) : error && error !== "The Exam is Closed for You" ? (
        <div>
          <Result
            icon={<FrownOutlined />}
            title="حدث خطأ أثناء معالجة الطلب. يرجى المحاولة مرة أخرى."
          />
        </div>
      ) : error && error === "The Exam is Closed for You" ? (
        <div>
          <Result
            icon={<SmileOutlined />}
            title="لقد قمت بالاجابة على الامتحان من قبل!"
          />
        </div>
      ) : !error ? (
        <div>
          <Result icon={<SmileOutlined />} title="تمت الاجابة " />
        </div>
      ) : (
        ""
      )}
    </Home>
  );
};
export default ExamQuestionType1;
