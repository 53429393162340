import axios from "axios";
import { API_BASE_URL } from "./constants";

const token = localStorage.getItem("token");

// if (!token) {
//     throw new Error("Bearer token not found in localStorage");
// }

const headers = {
    Authorization: `Bearer ${token}`,
};

export const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: headers
})