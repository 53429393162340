import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  DatePicker,
  message,
  App,
  Flex,
} from "antd";
import { PlusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDataContext } from "../../context/DataContext";
import PageTitle from "../../components/Title/PageTitle";
const { RangePicker } = DatePicker;

export default function AddExamForm({ id, topicId, setShowAddExamForm }) {
  const { notification } = App.useApp();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [totalMarks, setTotalMarks] = useState(null);
  const { questionData, setQuestionDataContext } = useDataContext();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const success = () => {
    notification.open({
      type: "success",
      content: "تمت اضافة سؤال",
    });
  };
  useEffect(() => {
    if (questionData) {
      // Populate form fields with data from questionData
      setTitle(questionData.title);
      setDescription(questionData.description);
      setStartTime(moment(questionData.startDateTimeString));
      setEndTime(moment(questionData.endDateTimeString));
      setTotalMarks(questionData.totalMarks);
    }
  }, [questionData]);

  const handleAddQuestionClick = () => {
    if (
      title.trim() === "" ||
      description.trim() === "" ||
      startDateTimeString === null ||
      endDateTimeString === null ||
      totalMarks === null
    ) {
      message.error("يرجى تعبية الحقول للاضافة سؤال.");
      return;
    }

    // Create an object with the data to pass
    const questionData = {
      title,
      description,
      startDateTimeString,
      endDateTimeString,
      totalMarks,
      id,
    };

    // Update the shared context with the question data
    setQuestionDataContext(questionData);

    // Navigate to the Questions page
    navigate("/Questions");
  };

  const onCancel = () => {
    setShowAddExamForm(false);
  };

  const navigate = useNavigate();
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
  };
  const startDateTimeString = startTime ? formatDateTime(startTime) : null;
  const endDateTimeString = endTime ? formatDateTime(endTime) : null;
  return (
    <>
      {contextHolder}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          width: "100%",
        }}
      >
        <Flex justify="space-between" align="center">
          <PageTitle>إضافة إختبار</PageTitle>
          <Button type={"text"} onClick={onCancel}>
            <ArrowLeftOutlined />
          </Button>
        </Flex>

        <Form style={{ width: "90%" }} layout="vertical">
          <Form.Item
            label={"العنوان"}
            rules={[
              { required: true, message: "العنوان مطلوب" },
              { min: 0, max: 255 },
            ]}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="اكتب العنوان"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label={"شرح"}
            rules={[
              { required: true, message: "الشرح مطلوب" },
              { min: 0, max: 255 },
            ]}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="شرح الاختبار"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={"الوقت البداية والنهاية"}>
            <RangePicker
              showTime
              size="large"
              style={{ width: "100%" }}
              value={[startTime, endTime]}
              onChange={(dates) => {
                setStartTime(dates[0]);
                setEndTime(dates[1]);
              }}
            />
          </Form.Item>
          <Form.Item label={"العلامة كاملة"}>
            <Input
              type="number"
              size="large"
              style={{ width: "100%%" }}
              placeholder="علامة كاملة للاختبار"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="text"
                icon={
                  <PlusCircleOutlined
                    style={{ fontSize: 20, color: "#2F3CED" }}
                  />
                }
                size="small"
                style={{ border: "none" }}
                onClick={() => {
                  if (handleAddQuestionClick() === true) {
                    navigate("/Questions");
                  }
                }}
              ></Button>
              <Typography className="related-title">إضافة سؤال</Typography>
            </div>
          </Form.Item>
        </Form>
      </div>

      {/* <div
        style={{
          backgroundColor: "#DDEDFF80",
          display: "flex",
          flex: "5",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      ></div> */}
    </>
  );
}
