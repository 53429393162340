import React, { useEffect, useState } from "react";
import { Button, List, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSingleCourse,
  fetchSingleCourse,
} from "../../redux/slices/coursesSlice";
import TopicCard from "../Cards/TopicCard";

const TopicsList = ({
  id,
  onVideoClick,
  selectedVideo,
  setShowAddExamForm,
  setShowAddLessonForm,
  setShowAddLiveSessionForm,
}) => {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadMoreClicked, setLoadMoreClicked] = useState(false);
  const singleCourse_get = useSelector(selectSingleCourse);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadSingleCourses = async () => {
      await dispatch(fetchSingleCourse(id));
      setInitLoading(false);
    };

    loadSingleCourses();
  }, [dispatch]);

  const onLoadMore = () => {
    setLoadMoreClicked(true);
  };

  const loadMore =
    !initLoading && !loading ? (
      <Button
        type="text"
        block
        style={{ color: "#EE4323" }}
        onClick={onLoadMore}
      >
        عرض المزيد
      </Button>
    ) : null;

  return (
    <Space
      direction="vertical"
      style={{
        height: "40vh",
        overflow: "scroll",
        padding: 10,
        width: "90%",
      }}
    >
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={!loadMoreClicked && loadMore}
        style={{ width: "90%" }}
        dataSource={
          loadMoreClicked
            ? singleCourse_get?.topic
            : singleCourse_get?.topic.slice(0, 5)
        }
        renderItem={(item) => (
          <List.Item style={{ width: "100%" }}>
            <TopicCard
              setShowAddExamForm={setShowAddExamForm}
              setShowAddLessonForm={setShowAddLessonForm}
              setShowAddLiveSessionForm={setShowAddLiveSessionForm}
              topic={item}
              selectedVideo={selectedVideo}
              onVideoClick={(videoUrl) => onVideoClick(videoUrl)}
              id={id}
            />
          </List.Item>
        )}
      />
    </Space>
  );

  //   return <>Topics list</>;
};
export default TopicsList;
