import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { uploadVideoSlice } from "../../redux/slices/uploadVideoSlice";
import "./addNewCuerce.scss";
const { Dragger } = Upload;

const AddNewCuerce = () => {
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    Description: "",
    order: 0,
    length: 0,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const validateForm = () => {
    const { title, Description, order, length } = formData;
    if (title && Description && order && length) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };
  useEffect(() => {
    validateForm();
  }, [formData]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    const data = {
      title: formData.title,
      description: formData.Description,
      order: formData.order,
      length: formData.length,
    };

    dispatch(uploadVideoSlice({ data }))
      .unwrap()
      .then((response) => {
        message.success("Data uploaded successfully.");
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.form, "text/html");

        // Access the form element and its "action" attribute
        const formElement = doc.querySelector("form");
        const actionAttribute = formElement.getAttribute("action");
        setResponseData(actionAttribute);

        console.log("Response Data:", responseData);
      })
      .catch((error) => {
        message.error("Data upload failed.");
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("file_data", file);
      setIsFileUploaded(true);
      // Simulate the upload progress (you can replace this with actual upload logic)
      const totalSize = file.size;
      let uploadedSize = 0;
      const progressInterval = 200;

      const uploadTimer = setInterval(() => {
        uploadedSize += 102400; // Simulate 100KB uploaded
        const progress = (uploadedSize / totalSize) * 100;

        if (progress >= 100) {
          clearInterval(uploadTimer);
          setUploadProgress(100);

          // Simulate a response from the server
          setTimeout(() => {
            ///  setResponseData({ message: "Upload successful." });
          }, 1000); // Simulate a response delay
        } else {
          setUploadProgress(progress);
        }
      }, progressInterval);
    }
  };

  return (
    <div className="NewCourseContainer">
      <div className="NewCourseTitleContainer">
        <p className="NewCourseTitle">اضافة فيديو جديد</p>
      </div>
      <Form style={{ marginLeft: 40 }} layout="vertical" autoComplete="off">
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: 70,
          }}
        >
          <Form.Item
            style={{ width: 400 }}
            name="title"
            label="اسم الدورة"
            rules={[
              {
                required: true,
                message: "Please enter a course name.",
              },
              {
                type: "string",
                min: 6,
                message: "Course name must be at least 6 characters.",
              },
            ]}
          >
            <Input
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              size="large"
              style={{ backgroundColor: "#F1F1F1", textAlign: "right" }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: 400 }}
            name="Description"
            label="الوصف"
            rules={[
              {
                required: true,
                message: "Please enter a description.",
              },
              {
                type: "string",
                min: 6,
                message: "Description must be at least 6 characters.",
              },
            ]}
          >
            <Input
              name="Description"
              value={formData.Description}
              onChange={handleInputChange}
              size="large"
              style={{ backgroundColor: "#F1F1F1", textAlign: "right" }}
            />
          </Form.Item>
          <Form.Item style={{ marginRight: "20px", width: "35%" }}>
            {/* <div style={{ height: "200px" }}>
                <Dragger
                  {...props}
                  style={{ border: "1px dashed #3D5CFF" }}
                  disabled={!responseData}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Browse Files To Upload Coerce Questions
                  </p>
                </Dragger>
              </div> */}

            {/* <label for="file">File:</label>
                <input type="file" name="file_data" id="file" />
                <br />  
                <input type="submit" name="submit" value="Submit"></input> */}
            <div style={formContainerStyle}>
              <Form
                method="POST"
                action={responseData}
                encType="multipart/form-data"
              >
                <label htmlFor="file" style={fileLabelStyle}>
                  <div style={draggerStyle}>
                    <p>انقر أو اسحب الملف إلى هذه المنطقة للتحميل</p>
                    <p>دعم تحميل ملف واحد</p>
                  </div>
                  <input
                    type="file"
                    name="file_data"
                    id="file"
                    style={fileInputStyle}
                    onChange={handleFileChange}
                  />
                </label>
                <div style={loadingBarStyle}>
                  <div
                    style={{
                      width: `${uploadProgress}%`,
                      ...loadingBarFillStyle,
                    }}
                  >
                    {uploadProgress.toFixed(2)}%
                  </div>
                </div>
                {responseData && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <input
                      type="submit"
                      name="submit"
                      value="ارفع الفيديو"
                      style={submitButtonStyle}
                    />
                  </div>
                )}
              </Form>
            </div>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: 70,
          }}
        >
          <Form.Item style={{ width: 400 }} name="order" label="ترتيب">
            <Input
              type="number"
              name="order"
              value={formData.order}
              onChange={handleInputChange}
              size="large"
              style={{ backgroundColor: "#F1F1F1", textAlign: "right" }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: 400 }}
            name="length"
            label="الوقت"
            rules={[
              {
                required: true,
                message: "Please enter a time.",
              },
              {
                // type: "number",
              },
            ]}
          >
            <Input
              name="length"
              value={formData.length}
              onChange={handleInputChange}
              size="large"
              style={{ backgroundColor: "#F1F1F1", textAlign: "right" }}
            />
          </Form.Item>
        </div>

        <div
          className="bottomButton"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button
            size="large"
            style={{
              position: "absolute",
              bottom: 10,
              width: 200,
            }}
            type="primary"
            onClick={handleSave}
            disabled={!isFormValid || !isFileUploaded}
          >
            حفظ
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewCuerce;
const formContainerStyle = {
  maxWidth: "500px",
  margin: "20px",
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  backgroundColor: "#f5f5f5",
};

const labelStyle = {
  display: "block",
  marginBottom: "10px",
};

const inputStyle = {
  display: "block",
  marginBottom: "10px",
};

const submitButtonStyle = {
  backgroundColor: "#3D5CFF",
  color: "#fff",
  padding: "10px 10px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  margin: "20px",
};

const draggerStyle = {
  border: "2px dashed #3D5CFF",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  borderRadius: "5px",
  backgroundColor: "#fff",
};

const fileLabelStyle = {
  display: "block",
  marginBottom: "10px",
};

const fileInputStyle = {
  display: "none",
};

const loadingBarStyle = {
  backgroundColor: "#e0e0e0",
  height: "20px",
  borderRadius: "5px",
  overflow: "hidden",
};

const loadingBarFillStyle = {
  backgroundColor: "#3D5CFF",
  height: "100%",
  transition: "width 0.2s",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const responseStyle = {
  marginTop: "20px",
  padding: "10px",
  backgroundColor: "#d9f5d9",
  borderRadius: "5px",
  textAlign: "center",
};
