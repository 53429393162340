import { Card, Button, Typography, Space, Avatar, App } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeletePopConfirm from "../Buttons/DeletePopConfirm";
import { deleteTopic } from "../../helpers/TopicsActions";
import { LeftOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import TopicIcons from "../icons/TopicIcons";

export default function TopicCard({
  topic,
  onVideoClick,
  id,
  selectedVideo,
  setShowAddExamForm,
  setShowAddLessonForm,
  setShowAddLiveSessionForm,
}) {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const handleDeleteClick = async (topicId) => {
    const response = await deleteTopic(topicId);
    if (response.status) {
      notification.success({ message: response.data.message });
    }
  };
  const styles = selectedVideo?.id == topic?.id && {
    boxShadow:
      "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
  };

  return (
    <Card
      key={topic.id}
      style={{
        ...styles,
        marginRight: 20,
        border: "none",
        borderRadius: 16,
        padding: 8,
        width: "100%",
      }}
      hoverable={true}
      onClick={() => {
        if (topic.video_id) {
          onVideoClick(topic);
        } else if (localStorage.getItem("role") === "4") {
          navigate(`/EditExam/${topic?.exam_id}`);
        } else if (localStorage.getItem("role") === "5") {
          navigate(`/ExamQuestionType1/${topic?.exam?.id}`);
        }
      }}
      // actions={[
      //   <Button
      //     onClicl={(e) => {
      //       e.stopPropagation();
      //       console.log("clicked");
      //     }}
      //   >
      //     btn action
      //   </Button>,
      // ]}
    >
      <Card.Grid
        hoverable={false}
        style={{
          padding: 3,
          display: "flex",
          boxShadow: "none",
          width: "22%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TopicIcons selectedVideo={selectedVideo} topic={topic} />
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{
          width: "55%",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <Typography className="live-ds">
          {topic.video_id
            ? topic?.video?.title || "بدون عنوان"
            : topic?.exam?.title}
        </Typography>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{
          width: "23%",
          display: "flex",
          flexDirection: "row",
          padding: 0,
          justifyContent: "flex-end",
          alignItems: "flex-start",
          boxShadow: "none",
          marginTop: 5,
        }}
      >
        <Space size={"small"} direction={"vertical"}>
          {topic?.exam_id && localStorage.getItem("role") === "4" ? (
            <Space size={5}>
              <Button
                type="text"
                size="small"
                href={`/EditExam/${topic?.exam_id}`}
              >
                <EditOutlined />
              </Button>
            </Space>
          ) : topic?.video_id && localStorage.getItem("role") === "4" ? (
            <>
              <Space size={5}>
                <DeletePopConfirm
                  title={"Delete topic"}
                  desc={"are you sure you want to delete this topic?"}
                  onConfirm={() => handleDeleteClick(topic.id)}
                >
                  <Button type="text" danger size="small">
                    <DeleteOutlined />
                  </Button>
                </DeletePopConfirm>
                <Button
                  type="text"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (topic.video.is_online === "Live") {
                      setShowAddLiveSessionForm();
                    } else {
                      setShowAddLessonForm();
                      console.log("clicked");
                    }
                    onVideoClick(topic);
                  }}
                  // href={`${
                  //   topic.video.is_online === "Live"
                  //     ? `/AddLiveLesson/${topic.id}`
                  //     : `/addlesson/${id}?lesson=${topic.id}`
                  // }`}
                >
                  <EditOutlined />
                </Button>
              </Space>
            </>
          ) : topic?.video_id && localStorage.getItem("role") === "5" ? (
            <LeftOutlined />
          ) : topic?.exam_id && localStorage.getItem("role") === "5" ? (
            <EditOutlined
              onClick={() => {
                navigate(`/ExamQuestionType1/${topic?.exam_id}`);
              }}
            />
          ) : null}
          <Typography.Text style={{ fontSize: "12px" }}>
            الترتيب : {topic.order}
          </Typography.Text>
        </Space>
      </Card.Grid>
    </Card>
  );
}
