import React from "react";
import { Card, Typography, Flex } from "antd";
import AddButtonsActions from "../DropDowns/AddButtonsActions";
export default function TeacherCourseCard({
  course,
  onClick,
  selectedCard,
  setShowAddLiveSessionForm,
  setShowAddExamForm,
  setShowAddLessonForm,
}) {
  console.log(course);
  return (
    <Card
      className={`card-info ${selectedCard === course.id ? "selected" : ""}`}
      key={course.id}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // height: "80%",
          padding: 17,
          boxShadow: "none",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2%",
          }}
        >
          <Typography.Title className="course-title">
            {course.title}
          </Typography.Title>
          <Typography.Text className="course-description">
            {course.capacity}ساعة من الفيديوهات التعليمية
          </Typography.Text>
        </div>
      </div>
      <Flex
        justify="space-between"
        alignItems={"flex-start"}
        style={{ padding: "10px 20px ", height: "fit-content" }}
      >
        <Typography.Title
          className="card-clicked-effect"
          level={5}
          type="secondary"
          style={{ padding: "0", margin: "0", fontSize: "12px" }}
        >
          {course?.videos_count} طلاب
        </Typography.Title>
        <AddButtonsActions
          setShowAddLiveSessionForm={(value) =>
            setShowAddLiveSessionForm(value)
          }
          setShowAddExamForm={(value) => setShowAddExamForm(value)}
          setShowAddLessonForm={(value) => setShowAddLessonForm(value)}
          id={selectedCard}
        />
      </Flex>
    </Card>
  );
}
