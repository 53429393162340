import {
  Button,
  DatePicker,
  Form,
  Input,
  Card,
  InputNumber,
  Select,
  Space,
  App,
  Flex,
} from "antd";
import dayjs from "dayjs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_TIME_FORMAT } from "../../helpers/constants";
import { useEffect, useState } from "react";
import {
  fetchLessonDetails,
  fetchTeacherCourses,
  selectLessonDetails,
  selectTeacherCourses,
} from "../../redux/slices/coursesSlice";
import { useDispatch, useSelector } from "react-redux";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
import { addLiveSession } from "../../pages/newLiveLesson/actions/actions";
import PageTitle from "../../components/Title/PageTitle";

dayjs.extend(customParseFormat);

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
const { Item } = Form;
export default function AddLiveSessionForm({
  id,
  topicId,
  setShowAddLiveSessionForm,
}) {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const [coursesOptions, setCoursesOptions] = useState();
  const dispatch = useDispatch();
  const courses = useSelector(selectTeacherCourses);
  const [loading, setLoading] = useState(false);
  const liveDetails = useSelector(selectLessonDetails);

  const fetchData = async () => {
    await dispatch(fetchTeacherCourses());
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getLiveDetails = async () => {
    if (typeof id !== "undefined") {
      await dispatch(fetchLessonDetails());
    } else {
      console.log("create");
    }
  };
  useEffect(() => {
    getLiveDetails();
  }, []);

  useEffect(() => {
    let coursesOptionsTemp = [];
    courses.forEach((item) => {
      coursesOptionsTemp.push({ label: item.title, value: item.id });
    });
    setCoursesOptions(coursesOptionsTemp);
  }, [courses]);

  const onCancel = () => {
    setShowAddLiveSessionForm(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.start_time = values.start_time.format(DATE_TIME_FORMAT);
    const response = await addLiveSession(id, values);
    if (response.status === 200) {
      notification.success({ message: response.data.Message });
    } else {
      notification.success({ message: response.data.Message });
    }
    setLoading(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  return (
    <>
      {typeof coursesOptions === "undefined" ? (
        <DefaultPageLoader />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 0,
            width: "100%",
          }}
        >
          <Flex justify={"space-between"} align="center">
            <PageTitle>إضافة بث مباشر</PageTitle>
            <Button type={"text"} onClick={onCancel}>
              <ArrowLeftOutlined />
            </Button>
          </Flex>
          <Form style={{ width: "90%" }} layout="vertical" onFinish={onFinish}>
            {/* <Item
              label={"الدرس"}
              name={"course_id"}
              rules={[{ required: true, message: "الدرس مطلوب" }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={coursesOptions}
              />
            </Item> */}
            <Item
              label={"العنوان"}
              name={"title"}
              rules={[
                { required: true, message: "العنوان مطلوب" },
                {
                  min: 0,
                  max: 255,
                  message: "العنوان يجب ان يكون بين ال0 وال255 محرف",
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              label={"الوصف"}
              name={"description"}
              rules={[
                { required: true, message: "الوصف مطلوب" },
                {
                  min: 0,
                  max: 255,
                  message: "الوصف يجب ان يكون بين ال0 وال255 محرف",
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              label={"المدة"}
              name={"duration"}
              rules={[
                { required: true, message: "المدة مطلوبة" },
                // {
                //   min: 30,
                //   max: 60,
                //   message: "المدة يجب على الاقل ان تكون اكبر من 30",
                // },
              ]}
            >
              <InputNumber
                min={30}
                style={{ width: "100%" }}
                controls={false}
              />
            </Item>
            <Item
              label={"موعد البداية"}
              name={"start_time"}
              rules={[{ required: true, message: "موعد البداية مطلوب" }]}
            >
              <DatePicker
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                format={DATE_TIME_FORMAT}
                style={{ width: "100%" }}
                size="large"
                showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
              />
            </Item>

            <Space size={"small"}>
              <Button
                style={{ border: "1px solid #A3A3A5", borderRadius: 16 }}
                size="large"
                onClick={onCancel}
              >
                إلغاء
              </Button>
              <Button
                style={{
                  backgroundColor: "#11142D",
                  border: "1px solid #A3A3A5",
                  borderRadius: 16,
                }}
                type={"primary"}
                loading={loading}
                size="large"
                htmlType="submit"
              >
                حفظ
              </Button>
            </Space>
          </Form>
        </div>
      )}
    </>
  );
}
