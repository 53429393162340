import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helpers/constants";

const EXAM_API = `${API_BASE_URL}/teacher/exams`;
const STUDENT_EXAM_API = `${API_BASE_URL}/student/exams`;
const STUDENT_ANSWERS_API = `${API_BASE_URL}/student/answers`;
const token = localStorage.getItem("token");

export const createExam = createAsyncThunk(
  "exam/createExam",
  async (examData) => {
    try {
      const formData = new FormData();
      formData.append("title", examData.title);
      formData.append("description", examData.description);
      formData.append("start_date", examData.start_date);
      formData.append("end_date", examData.end_date);
      formData.append("total_marks", examData.total_marks);
      formData.append("course_id", examData.course_id);

      examData.questions.forEach((question, index) => {
        formData.append(
          `questions[${index}][question_title]`,
          question.question_title
        );
        formData.append(
          `questions[${index}][question_description]`,
          question.question_description
        );
        formData.append(
          `questions[${index}][question_marks]`,
          question.question_marks
        );
        formData.append(`questions[${index}][type]`, question.type);

        if (question.type === "file" && question.file) {
          formData.append(`questions[${index}][file]`, question.file);
        }

        if (question.type === "options" && question.options) {
          question.options.forEach((option, optionIndex) => {
            formData.append(
              `questions[${index}][options][${optionIndex}][option]`,
              option.option
            );
            formData.append(
              `questions[${index}][options][${optionIndex}][options_correct]`,
              option.options_correct ? "1" : "0"
            );
          });
        }
      });

      const response = await axios.post(EXAM_API, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error creating exam:", error.message);
      throw new Error("Failed to create exam");
    }
  }
);
export const deleteTeacherExam = createAsyncThunk(
  "exam/deleteTeacherExam",
  async (id) => {
    try {
      const response = await axios.delete(`${EXAM_API}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Failed to delete Teacher exam");
    }
  }
);

export const fetchTeacherExams = createAsyncThunk(
  "exam/fetchTeacherExams",
  async () => {
    try {
      const response = await axios.get(EXAM_API, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      });

      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch Teacher exams");
    }
  }
);
export const fetchTeacherExamById = createAsyncThunk(
  "exam/fetchTeacherExamById",
  async (id) => {
    try {
      const response = await axios.get(`${EXAM_API}/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch Teacher exam by ID");
    }
  }
);
export const updateTeacherExam = createAsyncThunk(
  "exam/updateTeacherExam",
  async ({ id, examData }) => {
    try {
      const formData = new FormData();
      formData.append("title", examData.title);
      formData.append("description", examData.description);
      formData.append("start_date", examData.start_date);
      formData.append("end_date", examData.end_date);
      formData.append("total_marks", examData.total_marks);
      formData.append("course_id", examData.course_id);
      formData.append("_method", "PUT");

      examData.questions.forEach((question, index) => {
        formData.append(`questions[${index}][id]`, question.id);
        formData.append(`questions[${index}][question_title]`, question.title);
        formData.append(
          `questions[${index}][question_description]`,
          question.description
        );
        formData.append(`questions[${index}][question_marks]`, question.marks);
        formData.append(`questions[${index}][type]`, question.type);

        if (question.type === "file" && question.file) {
          formData.append(`questions[${index}][file]`, question.file);
        }

        if (question.type === "options" && question.options) {
          question.options.forEach((option, optionIndex) => {
            formData.append(
              `questions[${index}][options][${optionIndex}][option]`,
              option.title
            );
            formData.append(
              `questions[${index}][options][${optionIndex}][options_correct]`,
              option.is_correct
            );
          });
        }
      });

      const response = await axios.post(`${EXAM_API}/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error updating exam:", error.message);
      throw new Error("Failed to update exam");
    }
  }
);

export const fetchStudentExams = createAsyncThunk(
  "exam/fetchStudentExams",
  async (id = null) => {
    try {
      let url = STUDENT_EXAM_API;
      if (id !== null) {
        // Append the id to the URL if it's provided
        url += `/${id}`;
      }

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch Student exams");
    }
  }
);
export const postStudentAnswers = createAsyncThunk(
  "exam/postStudentAnswers",
  async (examData) => {
    try {
      const formData = new FormData();

      formData.append("exam_id", examData.exam_id);

      examData.questions.forEach((question, index) => {
        formData.append(`questions[${index}][id]`, question.id);
        formData.append(`questions[${index}][type]`, question.type);

        if (question.type === "text") {
          formData.append(`questions[${index}][answer]`, question.answer);
        }

        if (question.type === "options") {
          question.options.forEach((option, optionIndex) => {
            formData.append(
              `questions[${index}][options][${optionIndex}][id]`,
              option.id
            );
            formData.append(
              `questions[${index}][options][${optionIndex}][option]`,
              option.option
            );
            formData.append(
              `questions[${index}][options][${optionIndex}][options_selected]`,
              option.options_selected
            );
          });
        }

        if (question.type === "file") {
          formData.append(`questions[${index}][file]`, question.file);
        }
      });

      const response = await axios.post(STUDENT_ANSWERS_API, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (message) {
      console.error("Error posting answers:", message?.response?.data?.Message);

      throw new Error(message?.response?.data?.Message);
    }
  }
);

const examSlice = createSlice({
  name: "exam",
  initialState: {
    exam: null,
    exam_s: [],
    status: "idle",
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    resetStatus: (state) => {
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createExam.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createExam.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exam = action.payload;
      })
      .addCase(createExam.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTeacherExams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeacherExams.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exam_s = action.payload;
      })
      .addCase(fetchTeacherExams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTeacherExamById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeacherExamById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exam = action.payload;
      })
      .addCase(fetchTeacherExamById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteTeacherExam.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTeacherExam.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Optionally, you can update the state or remove the deleted exam from the state
      })
      .addCase(deleteTeacherExam.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateTeacherExam.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTeacherExam.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exam = action.payload;
      })
      .addCase(updateTeacherExam.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStudentExams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStudentExams.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exam_s = action.payload;
      })
      .addCase(fetchStudentExams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postStudentAnswers.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postStudentAnswers.fulfilled, (state, action) => {
        state.status = "succeeded";
        // You can update the state based on the response if needed
        state.Message = action.Message;
        console.log("Response from posting answers:", action.payload);
      })

      .addCase(postStudentAnswers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const selectExamState = (state) => state.exam;
export const { clearError } = examSlice.actions;
export const selectPostStudentAnswersStatus = createSelector(
  [selectExamState],
  (examState) => examState.status
);
const selectCreateState = (state) => state.exam;
export const { resetStatus } = examSlice.actions;
export const SelectResetStatus = createSelector(
  [selectExamState],
  (examState) => examState.status
);

export const selectPostStudentAnswersMessage = createSelector(
  [selectExamState],
  (examState) => {
    // Assuming the payload structure is { Message: "Some message" }
    return examState.exam ? examState.exam.Message : null;
  }
);

export default examSlice.reducer;
