import React, { useEffect, useState } from "react";
import { Button, Typography, Input, Form, Spin, Result, Modal } from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SmileOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./CorrectAnswers.scss";
import { useNavigate } from "react-router-dom";
import Home from "../home/Home";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnswersByIds,
  createAnswer,
} from "../../redux/slices/AnswersSlice";
import { fetchTeacherExamById } from "../../redux/slices/Questions";

const { TextArea } = Input;

const CorrectAnswers = () => {
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);
  const [answerMarks, setAnswerMarks] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { examId, id } = useParams();
  const answersState = useSelector((state) => state.answers);
  const [postData, setPostData] = useState([]);
  const [isDone, setIsDone] = useState(false);
  useEffect(() => {
    dispatch(fetchAnswersByIds({ examId, id }));
  }, [dispatch]);

  const { data, status } = answersState;

  useEffect(() => {
    dispatch(fetchTeacherExamById(examId));
  }, [dispatch, examId]);

  const exam = useSelector((state) => state?.exam?.exam);
  const handleMarksIncrement = () => {
    if (answerMarks < data[currentAnswerIndex]?.question?.marks) {
      setAnswerMarks((prevMarks) => prevMarks + 1);
    }
  };
  const handleMarksDecrement = () => {
    if (answerMarks > 0) {
      setAnswerMarks((prevMarks) => prevMarks - 1);
    }
  };
  const navigateToPreviousAnswer = () => {
    // Decrement the currentAnswerIndex to navigate to the previous answer
    setCurrentAnswerIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );

    // Retrieve the mark for the previous question
    const previousMark = postData.find(
      (item) => item.id === data[currentAnswerIndex]?.id
    )?.mark;

    // Set the retrieved mark as the answerMarks for the previous question
    setAnswerMarks(previousMark || 0);
  };
  const [loading, setLoading] = useState("تنزيل");
  const downloadFile = async (file) => {
    try {
      setLoading(<LoadingOutlined />);

      const response = await fetch(file, { redirect: "follow" });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      const filename = file.substring(file.lastIndexOf("/") + 1);
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading("تنزيل");

      window.open(file, "_blank");
    }
  };
  const finalPostData = {
    _method: "Put",
    questions: postData,
  };
  const handleNextClick = () => {
    const currentQuestionId = data[currentAnswerIndex]?.id;

    // Check if the current question data is already in postData
    const isQuestionDataExist = postData.some(
      (item) => item.id === currentQuestionId
    );

    // If it doesn't exist, add it to postData
    if (!isQuestionDataExist) {
      // Create an object for the current question
      const currentQuestionData = {
        id: currentQuestionId,
        mark: answerMarks,
      };

      // Update the postData with the current question data
      setPostData((prevData) => [...prevData, currentQuestionData]);
    }

    // Increment the currentAnswerIndex to display the next answer
    setCurrentAnswerIndex((prevIndex) =>
      prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex
    );

    // Reset the answerMarks to 0 for the next question
    setAnswerMarks(0);
  };

  // ...

  // When you need to send the request, you can construct the final object
  const handleSaveButton = async () => {
    try {
      const currentQuestionId = data[currentAnswerIndex]?.id;

      // Create an object for the current question
      const currentQuestionData = {
        id: currentQuestionId,
        mark: answerMarks,
      };

      // Update the postData with the current question data
      setPostData((prevData) => [...prevData, currentQuestionData]);

      // Create the finalPostData object
      const finalPostData = {
        _method: "Put",
        questions: postData,
      };
      //console.log(finalPostData);
      setIsModalVisible(true);
      // if (postData?.length === data?.length) {
      //   await dispatch(createAnswer({ examId, id, finalPostData }));
      // }
    } catch (error) {
      // Handle errors if needed
      console.error("Error dispatching createAnswer:", error);
    }
  };
  console.log(finalPostData);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to handle the click on the "حفظ" button

  // Function to handle the confirmation of the modal
  const handleModalOk = async () => {
    try {
      // ... (existing code for dispatching createAnswer)
      if (postData?.length === data?.length) {
        await dispatch(createAnswer({ examId, id, finalPostData }));
        setIsDone(true);
      }

      // Close the modal after dispatching
      setIsModalVisible(false);
    } catch (error) {
      // Handle errors if needed
      console.error("Error dispatching createAnswer:", error);
    }
  };

  // Function to handle the cancellation of the modal
  const handleModalCancel = () => {
    // Close the modal without dispatching
    setIsModalVisible(false);
  };

  return (
    <Home>
      {status === "loading" || status === "idle" ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Spin size="large" />
        </div>
      ) : data?.length === 0 ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Result icon={<SmileOutlined />} title="لا يوجد اي اجابة بعد" />
        </div>
      ) : isDone ? (
        <Result
          status="success"
          title="تم التصحيح "
          // subTitle="Your answers have been saved successfully."
        />
      ) : (
        <div className="correctContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              height: "100%",
              flex: 10,
            }}
          >
            <div className="live-answers">
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <Typography className="live-title">
                  اختبار {exam?.exam?.title}
                </Typography>
              </div>
              <Typography className="related-title">
                ضع العلامة لكل سؤال و اضغط حفظ
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "55%",
                }}
              >
                <span className="related-title">
                  صفحة{" "}
                  <span className="page-number">{currentAnswerIndex + 1}</span>{" "}
                  من {data?.length}
                </span>
              </div>
            </div>

            <Form>
              <div key={data[currentAnswerIndex]?.id}>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40%",
                      gap: 20,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        width: "50%",
                      }}
                    >
                      <div className="question-number">
                        <Typography className="page-number">
                          {currentAnswerIndex + 1}
                        </Typography>
                      </div>
                      <Typography className="related-title">
                        {data[currentAnswerIndex]?.question?.title}؟
                      </Typography>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#eef6ff",
                        border: "1px solid #808191",
                        borderRadius: 12,
                        display: "flex",
                        flexDirection: "row",
                        height: "30%",
                        padding: 2,
                      }}
                    >
                      <Button
                        shape="circle"
                        icon={
                          <PlusOutlined
                            style={{ color: "#fff", fontWeight: 700 }}
                          />
                        }
                        style={{ backgroundColor: "#11142D" }}
                        onClick={handleMarksIncrement}
                      ></Button>

                      <Typography
                        className="page-number"
                        style={{ padding: 5 }}
                      >
                        {answerMarks}
                      </Typography>

                      <Button
                        shape="circle"
                        style={{ backgroundColor: "#11142D" }}
                        icon={
                          <MinusOutlined
                            style={{ color: "#fff", fontWeight: 700 }}
                            onClick={handleMarksDecrement}
                          />
                        }
                      ></Button>
                    </div>
                    <Typography className="related-title">
                      العلامة كاملة:
                      <span>{data[currentAnswerIndex]?.question?.marks}</span>
                    </Typography>
                  </div>
                </Form.Item>

                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "30%",
                      justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    {data[currentAnswerIndex]?.answer ? (
                      <TextArea
                        placeholder="الجواب"
                        style={{
                          height: 170,
                          resize: "none",
                          borderRadius: 16,
                        }}
                        disabled
                        value={data[currentAnswerIndex]?.answer}
                      />
                    ) : data[currentAnswerIndex]?.file ? (
                      <Button
                        style={{
                          border: "1px solid #11142D",
                          borderRadius: 12,
                          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",

                          marginRight: "10%",
                        }}
                        size="large"
                        onClick={() =>
                          downloadFile(data[currentAnswerIndex]?.file)
                        }
                      >
                        {loading}
                      </Button>
                    ) : null}
                  </div>
                </Form.Item>
              </div>

              <div style={{ marginRight: 40, marginTop: 0, marginLeft: 20 }}>
                <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <Button
                    style={{
                      border: "1px solid #A3A3A5",
                      borderRadius: 16,
                      width: "10%",
                      display: currentAnswerIndex > 0 ? "block" : "none", // Conditionally show the button
                    }}
                    size="large"
                    onClick={navigateToPreviousAnswer}
                  >
                    رجوع
                  </Button>

                  {currentAnswerIndex === data?.length - 1 ? (
                    <Button
                      style={{
                        backgroundColor: "#11142D",
                        border: "1px solid #A3A3A5",
                        borderRadius: 16,
                        width: "10%",
                        color: "#fff",
                      }}
                      onClick={handleSaveButton}
                      size="large"
                    >
                      حفظ
                    </Button>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: "#11142D",
                        border: "1px solid #A3A3A5",
                        borderRadius: 16,
                        width: "10%",
                        color: "#fff",
                      }}
                      onClick={handleNextClick}
                      size="large"
                    >
                      التالي
                    </Button>
                  )}
                  <Modal
                    title={
                      <Typography style={{ textAlign: "center", fontSize: 40 }}>
                        هل أنت متأكد؟
                      </Typography>
                    }
                    visible={isModalVisible}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}
                    // Adding footer with OK and Cancel buttons
                    footer={(_, {}) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          size="large"
                          onClick={handleModalCancel}
                          style={{
                            backgroundColor: "#fff",
                            color: "#11142D",
                            border: "1px solid #11142D",
                            width: "25%",
                            borderRadius: 16,
                          }}
                        >
                          الغاء
                        </Button>
                        ,
                        <Button
                          size="large"
                          onClick={() => {
                            handleModalOk();
                            // navigate("/dashboard");
                          }}
                          style={{
                            backgroundColor: "#11142D",
                            color: "#fff",
                            width: "25%",
                            borderRadius: 16,
                          }}
                        >
                          تأكيد
                        </Button>
                        ,
                      </div>
                    )}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#11142D",
                        fontSize: "16px",
                      }}
                    >
                      سيتم وضع علامات الاسئلة
                    </p>
                  </Modal>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Home>
  );
};

export default CorrectAnswers;
