import React from "react";
import { Avatar } from "antd";
import { ExamIcon, PauseIcon, PlayIcon } from "../icons/Icons";
import { UserOutlined } from "@ant-design/icons";

export default function TopicIcons({ selectedVideo, topic }) {
  console.log("selectedVideo", selectedVideo);
  console.log("topic", topic);
  let icon = null;
  if (selectedVideo?.id == topic?.id) {
    icon = <PauseIcon />;
  } else if (topic.exam_id) {
    icon = <ExamIcon />;
  } else if (topic.video_id) {
    icon = <PlayIcon />;
  } else {
    icon = <UserOutlined />;
  }
  return <Avatar size={"large"} icon={icon} />;
}
